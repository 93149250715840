import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink, faPowerOff, faUser, faCoins, faBolt, faBullseye, faHeadset } from "@fortawesome/free-solid-svg-icons";

function initFontAwesome() {
  library.add(faLink);
  library.add(faUser);
  library.add(faPowerOff);
  library.add(faCoins);
  library.add(faBolt);
  library.add(faBullseye);
  library.add(faHeadset);
}

export default initFontAwesome;
