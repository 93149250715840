import React, { useState, useEffect, useRef } from 'react';

function LandingPageCarousel() {
    const featureTextRef = useRef(null);
    const [position, setPosition] = useState(0);

    const carouselText = [
        "New users get 100 credits for free",
        "Skip tracing on demand, no subscriptions, no minimum orders",
        "Dial phone numbers with over 90% accuracy",
        "Perfect for wholesalers, agents, and investors"
    ];

    // Encode messages into a single string
    var encodedText = carouselText.map((msg, idx) => {
        const backspaces = idx !== 0 ? '^'.repeat(carouselText[idx - 1].length) + ']' : '';
        return '|'.repeat(30) + backspaces+"]"+"|||||||||||" + msg;
    }).join('')+"|".repeat(30)+"^".repeat(carouselText[carouselText.length-1].length);
    encodedText = encodedText.slice(25)
    const waitForMs = ms => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    useEffect(() => {
        const processChar = async () => {
            const currentChar = encodedText[position];
            const element = featureTextRef.current;

            if (currentChar === '^') {
                element.innerHTML = element.innerHTML.slice(0, -1); // Backspace
            } else if (currentChar === "]") {
                element.innerHTML = ""; // Clear all text
            } else if (currentChar !== '|') {
                element.innerHTML += currentChar; // Type
            }

            let delay = currentChar === '|' ? 50 : 30;
            await waitForMs(delay);
            setPosition((prev) => (prev + 1) % encodedText.length);
        };

        processChar();
    }, [position]);

    return (
            <span id="feature-text" style={{ fontWeight: "300" }} ref={featureTextRef} className="text-gradient-primary"></span>
    );
}

export default LandingPageCarousel;
