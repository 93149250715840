import React, { Component } from 'react';
import './App.css';

class CopyableLink extends Component {
  state = { copied: false };

  handleCopy = async () => {
    await navigator.clipboard.writeText(this.props.link);
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 3000);  // Reset after 3 seconds
  };

  render() {
    return (
        <React.Fragment>
      <span className="copyable-link" onClick={this.handleCopy}>
        <code>{`${this.props.link}`}</code>
      </span>
      {this.state.copied && <span className="copied">Copied!</span>}
      </React.Fragment>
    );
  }
}

export default CopyableLink;
