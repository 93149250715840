import React, { Fragment } from "react";

import Hero from "../components/Hero";
import Content from "../components/Content";
import Application from "../Application";
const Lists = () => (
  <Fragment>
   <Application></Application>
  </Fragment>
);

export default Lists;
