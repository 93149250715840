import React from "react";

const Footer = () => (
  <footer className="bg-light p-3 text-center" style={{display:"none"}}>
    <div className="ninjaLogo" />
    <p>
    © 2023 Ninja Real Estate Services LLC. All rights reserved.
    </p>
  </footer>
);

export default Footer;
