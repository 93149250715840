import React, { useState, useEffect } from 'react';

function Field(props) {
    const [inputValue, setInputValue] = useState(props.value);
  
    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };
  
    const handleInputBlur = () => {
      sendValue(); // Call the function to send the new value
    };
  
    const handleInputKeyDown = (event) => {
      if (event.key === 'Enter') {
        sendValue(); // Call the function to send the new value when Enter key is pressed
      }
      else if (event.key === 'Escape') {
        setInputValue(props.value); // Set the input value to the props value on Escape key
      }
    };
  
    const sendValue = () => {
      // Perform actions to send the new value
      console.log('New value:', inputValue);
      console.log(props);
      props.callback(props.name,inputValue);
    };
  
    return (
      <input
        type={props.type}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onKeyDown={handleInputKeyDown}
        style={{color:inputValue==props.value?"black":"red"}}
      />
    );
  }
  
  export default Field;