import React, { useState, useEffect } from "react";
import { NavLink as RouterNavLink, useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

import "../App.css";


const host = window.location.hostname;
const root = host === 'localhost' ? 'http://localhost:80' : 'https://api2.ninjaskiptracing.com';

var testMode = host === 'localhost';


const NavBar = () => {

  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [userInfo,setUserInfo] = useState({credits:0,isAdmin:false,affiliateCode:"",earnedCredits:0});
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    logout,
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);
  const history = useHistory();

  var showNavBar = true;

  if(location.pathname=="/unlimited"||location.pathname=="/unlimited/"){
    showNavBar = false;
  }

  const logoutWithRedirect = () =>
    logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
    });

    useEffect(() => {

      let params = new URLSearchParams(window.location.search);
      let ref = params.get('ref');
      if(ref){
        localStorage.setItem("ref",ref);
        history.push('/');
      }

      const getUserInfo = async () => {
        try{
          if(isAuthenticated){
        const token = await getAccessTokenSilently();
        const response = await fetch(`${root}/v1/getUserInfo`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        });
  
        const data = await response.json();
        if(data&&data.credits&&typeof data.credits === 'number'){
          setUserInfo(data);
        }
      }
      }
        catch(err){
          console.log(err);
        }
      };
    
      const setRef = async () => {
        try{
          if(isAuthenticated&&localStorage.getItem("ref")){
            var ref = localStorage.getItem("ref");
        const token = await getAccessTokenSilently();
        const response = await fetch(`${root}/v1/setRef`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            
          },
          body: JSON.stringify({ ref }),
        });
        const data = await response.json();
        if(data?.status=="success"){
          localStorage.removeItem("ref");
        }
      }
      }
        catch(err){
          console.log(err);
        }
      };
    

      getUserInfo();
      setRef();
    }, [location]);
console.log(42,userInfo.isAdmin,isAuthenticated,userInfo.isAdmin&&isAuthenticated);

  return (
    <div className="nav-container" style = {{height:"0px"}}>
    <Navbar className = "ninjaNav" color="light" light expand="md" container={false}>
        <Container>
          <NavbarBrand className={showNavBar?"ninjaLogo":"invisible"}  style = {{marginRight:"10px"}} />
          <NavbarToggler className={showNavBar?"":"invisible"} onClick={toggle} />
          <Collapse className={showNavBar?"navBackground":"navBackground invisible"} isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Home
                </NavLink>
              </NavItem>
              {false && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/external-api"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    External API
                  </NavLink>
                </NavItem>
              )}
                            {isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/upload"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Upload
                  </NavLink>
                </NavItem>
              )}
                            {isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/lists"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Lists
                  </NavLink>
                </NavItem>
              )}
                                                                      {isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/affiliate"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Affiliate
                  </NavLink>
                </NavItem>
              )}
                                          {userInfo.isAdmin&&isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/admin"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Admin
                  </NavLink>
                </NavItem>
              )}
              <li className="nav-item"><a style = {{cursor:"pointer"}} href = "/blog/" class = "nav-link" target="_blank" rel="noreferrer noopener">Blog</a></li>     
              <li className="nav-item"><a style = {{cursor:"pointer"}} href = "https://propwire.com" class = "nav-link" target="_blank" rel="noreferrer noopener">Get Free Data</a></li>
              <li className="nav-item"><a style = {{cursor:"pointer"}} href = "/callers/" class = "nav-link" >Cold Callers</a></li>
            </Nav>
            <Nav className="d-none d-md-flex d-md-block" navbar>
            <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="contact  btn-margin d-block mr-2 blueButton"
                    href="mailto:info@ninjaskiptracing.com?subject=Skip%20Tracing%20Inquiry"
                  >
                    info@ninjaskiptracing.com
                  </Button>
                </NavItem>
              {!isAuthenticated && (
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin d-block blueButton"
                    onClick={() => loginWithRedirect()}
                  >
                    Log in
                  </Button>
                </NavItem>
              )}
              {isAuthenticated && (
                <React.Fragment>
                  <span className="credit-counter" style = {{display:"inline"}}>{userInfo.credits?userInfo.credits:0} credits</span>
                <UncontrolledDropdown nav inNavbar style = {{display:"inline"}}>
                  <DropdownToggle nav caret id="profileDropDown" style = {{display:"inline"}}>
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="50"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>{user.name}</DropdownItem>
                    {false&&(
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/profile"
                      className="dropdown-profile"
                      activeClassName="router-link-exact-active"
                    >
                      <FontAwesomeIcon icon="user" className="mr-3" /> Profile
                    </DropdownItem>)}
                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={() => logoutWithRedirect()}
                    >
                      <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
                      out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                </React.Fragment>
              )}
            </Nav>
            {!isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    block
                    onClick={() => loginWithRedirect({})}
                  >
                    Log in
                  </Button>
                </NavItem>
              </Nav>
            )}
            {isAuthenticated && (
              <Nav
                className="d-md-none justify-content-between"
                navbar
                style={{minHeight: 170 }}
              >
                <NavItem>
                  <span className="user-info">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile d-inline-block rounded-circle mr-3"
                      width="50"
                    />
                    <h6 className="d-inline-block">{user.name}</h6>
                  </span>
                </NavItem>
                {false&&(
                <NavItem>
                  <FontAwesomeIcon icon="user" className="mr-3" />
                  <RouterNavLink
                    to="/profile"
                    activeClassName="router-link-exact-active"
                  >
                    Profile
                  </RouterNavLink>
                </NavItem>)}
                <NavItem>
                  <FontAwesomeIcon icon="power-off" className="mr-3" />
                  <RouterNavLink
                    to="#"
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    Log out
                  </RouterNavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    
    </div>
  );
};

export default NavBar;
