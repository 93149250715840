import React, { Fragment } from "react";

import Hero from "../components/Hero";
import Content from "../components/Content";
import Application from "../Application";

const Privacy = () => (
    <Fragment>
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
whiteSpace: 'pre-wrap'
    }}>
    <div style = {{width:"50%",marginTop:"100px"}}>
      {`
Privacy Policy for Ninja Skip Tracing
Ninja Real Estate Services, LLC


Ninja Skip Tracing (“Ninja Skip Tracing,” “we,” or “us”) values your privacy. We offer affordable real estate data through our skip tracing services. This Privacy Policy (“Policy”) describes how we collect, use, and disclose information about users of our website and the services available through it (“Services”). By visiting the Site or using our Services, you agree that your information will be handled as described in this Policy. Your use of our Services, and any dispute over privacy, is subject to this Policy and our Terms of Use, including limitations on damages and dispute resolution. Our Terms of Use are incorporated by reference into this Policy.

Collection of Information
We collect information directly from you, from non-affiliated entities, and by automated means. The type of information we collect depends on your interaction with our Services or website but generally includes:

Registration: You will need to create an account to access our Services. We collect information such as your name, email address, phone number, and the market you operate in.
Activity: We collect information about your activities, including pages viewed and when you upload lists with data for skip tracing services.
Communications: We maintain records related to customer support requests, including the name, contact information, and the nature of the request. You can also sign up to receive newsletters, updates, and marketing information.
Usage Data: We automatically collect data such as IP address, browser type, device type, referring website, and usage patterns through cookies, local storage, and analytics tools.
Use of Information
We use the information collected for the following purposes:

Providing and Improving Our Services: To maintain, improve, and develop new features for our Site and Services.
Marketing and Communications: To communicate with you regarding your use of our Services, respond to inquiries, and provide marketing materials.
Research and Analytics: To better understand user interactions with our Services and improve them.
Protecting Rights and Interests: To ensure the safety and security of Ninja Skip Tracing, detect fraud, and enforce our policies.
Legal Compliance: To comply with legal obligations and respond to legal requests.
Disclosure of Information
We may share your information in the following circumstances:

Users: For users accessing through enterprise accounts, administrators and authorized personnel may have access to your account and usage data.
Service Providers: We may share your information with third-party service providers for tasks such as processing payments or performing services on our behalf.
Third-Party Marketing: We may send marketing materials about companies we think you may be interested in.
Business Transfers: In the event of a business transfer, merger, or acquisition, your information may be transferred to the new entity.
Protecting Rights and Interests: To protect the safety, rights, or security of Ninja Skip Tracing or others.
Legal Compliance: To comply with legal obligations, including in response to subpoenas or legal processes.
Analytics and Tracking
We and our third-party providers use cookies, pixel tags, and similar technologies to collect information about how you use our Site and Services. You can control some tracking preferences through your browser settings.

Interest-Based Advertising
We work with third-party ad companies to serve targeted ads based on your activities. You can opt out of targeted ads by visiting www.aboutads.info/choices.

Security
We have implemented safeguards to protect your personal information. However, no data transmission or storage can be guaranteed to be completely secure. We encourage you to protect your account by using strong passwords and securing your devices.

Your Choices
You may opt out of receiving promotional emails by following the unsubscribe instructions or contacting us directly. Please note that it may take up to 10 business days to process your request. Even after opting out of promotional messages, we may still send you communications related to your account or our Services.

Contact Us
For any questions regarding these Terms, please contact us at:
Ninja Real Estate Services, LLC
info@ninjaskiptracing.com
+1 (774) 462-0422`}
</div>
    </div>
  </Fragment>
  );

  export default Privacy;
