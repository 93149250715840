import React, { Fragment } from "react";

import Hero from "../components/Hero";
import Content from "../components/Content";
import Application from "../Application";

const Terms = () => (
    <Fragment>
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
whiteSpace: 'pre-wrap'
    }}>
    <div style = {{width:"50%",marginTop:"100px"}}>
      {`Terms of Use for Ninja Skip Tracing
Ninja Real Estate Services, LLC

These Terms of Use ("Terms") are a legal contract between You and Ninja Real Estate Services, LLC ("We" or "Us"), governing Your use of all content, services, and materials provided through our website, Ninja Skip Tracing ("Website"). This includes text, data, software, and more (collectively referred to as "Materials").

READ THESE TERMS CAREFULLY BEFORE USING THIS WEBSITE. BY USING THIS WEBSITE, YOU ACCEPT AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT ACCEPT THESE TERMS, YOU CANNOT USE THIS WEBSITE.

PLEASE READ THE “DISPUTE RESOLUTION AND ARBITRATION; CLASS ACTION WAIVER” SECTION BELOW, AS IT REQUIRES YOU TO RESOLVE DISPUTES ON AN INDIVIDUAL BASIS THROUGH BINDING ARBITRATION.

1. GENERAL USE
You must be at least 18 years of age to use this Website. We grant you a limited, non-exclusive, and non-transferable license to use and display the Materials for your internal business purposes. Any unauthorized use of the Materials, such as modifying or reproducing them for commercial purposes, will terminate this license immediately.

2. ACCOUNT REGISTRATION
To access certain areas or services of the Website, you may need to register for an account. Registration requires a working email, name, and other market-related information. You are responsible for maintaining the confidentiality of your account credentials. Should you believe your account security has been compromised, you must notify us immediately.

3. MEMBERSHIPS
By registering, you become a “Member” with access to certain restricted areas and services. Memberships are non-transferable, and any breach of these Terms may result in termination of your account.

4. PAYMENTS
You agree to pay all fees related to the services you use. We may suspend or terminate your account if payment is late or if payment methods cannot be processed. All purchases are final, and no refunds will be provided.

5. PROHIBITED USE OF BOTS OR MULTIPLE ACCOUNTS
You agree not to use any automated systems (including bots, scripts, or other tools) or create multiple accounts to exploit, abuse, or otherwise misuse any free credits, trials, or services offered by the Website. Such actions are considered a breach of these Terms and may result in the immediate termination of your account and access to our services without notice.

6. ELECTRONIC COMMUNICATIONS
By using the Website, you consent to receive electronic communications, including notices about fees, transactions, and other account-related information.

7. PRIVACY POLICY
Please review our Privacy Policy to understand how we handle your personal information.

8. THIRD-PARTY LINKS
We may provide links to third-party websites for convenience. We are not responsible for the content or services provided on third-party websites, and using such links is at your own risk.

9. SUBMISSIONS
Any submissions, feedback, or content you provide to the Website ("User Submissions") are non-confidential and non-proprietary. By submitting content, you grant us an irrevocable license to use, modify, and distribute your submission for any purpose.

10. UNAUTHORIZED ACTIVITIES
You agree not to use the Website for any purpose other than those explicitly permitted. Unauthorized uses include, but are not limited to, using the Website for illegal activities, impersonation, or any other prohibited conduct listed in these Terms.

11. PROPRIETARY RIGHTS
All rights in the Website and Materials are owned by Ninja Real Estate Services, LLC. Any unauthorized use, reproduction, or distribution of the Materials is prohibited.

12. DISCLAIMER OF WARRANTIES
THIS WEBSITE IS PROVIDED “AS IS” WITHOUT WARRANTY. WE DISCLAIM ALL WARRANTIES, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT GUARANTEE THAT THE WEBSITE WILL BE ERROR-FREE OR UNINTERRUPTED.

13. LIMITATION OF LIABILITY
TO THE FULLEST EXTENT PERMITTED BY LAW, WE WILL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF YOUR USE OF THE WEBSITE OR SERVICES. OUR TOTAL LIABILITY WILL NOT EXCEED $100 OR THE AMOUNT YOU PAID US FOR SERVICES.

14. LOCAL LAWS
If you use this Website outside of the United States, you are responsible for following all applicable local laws.

15. DISPUTE RESOLUTION AND ARBITRATION; CLASS ACTION WAIVER
You agree that any disputes arising out of or relating to these Terms or your use of the Website or our Services shall be resolved by final and binding arbitration before the American Arbitration Association (“AAA”) under its then-current AAA Commercial Arbitration Rules. The arbitration will take place in a mutually agreed-upon location. All legal disputes not subject to arbitration will be handled in the courts of the appropriate jurisdiction.

16. GENERAL
We may terminate your access to the Website at our discretion if you violate these Terms. These Terms are governed by the applicable laws of the United States. Any disputes not subject to arbitration will be resolved in the courts of the appropriate jurisdiction.

Contact Us
For any questions regarding these Terms, please contact us at:
Ninja Real Estate Services, LLC
info@ninjaskiptracing.com
+1 (774) 462-0422`}
</div>
    </div>
  </Fragment>
  );

  export default Terms;
